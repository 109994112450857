/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import IRPageWrapper from "../../../../layouts/IRPageWrapper"
import IRNav from "../../../../components/IRNav"
import IRBG from "../../../../components/IRBG"
import GeneralNav from "../../../../components/GeneralNav"
import HomeLogo from "../../../../components/HomeLogo"

class General2016Page extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title>General Corporate Information</title>
        </Helmet>
        <section className="hero is-small">
          <div className="columns">
            <div className="column is-offset-1">
              <div className="section">
                <HomeLogo />
                <h1 className="title" id="title-line">
                  General Corporate Information
                </h1>
                <IRNav />
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <GeneralNav />
          <div className="column is-offset-1">
            <table id="customers">
              <tr>
                <td>Stock Name:</td>
                <td>EA TECH</td>
              </tr>
              <tr>
                <td>Stock Code</td>
                <td>5259</td>
              </tr>
              <tr>
                <td>Listing</td>
                <td>Main Market</td>
              </tr>
              <tr>
                <td>Sector</td>
                <td>Transportation & Logistics</td>
              </tr>
              <tr>
                <td>Company Activities</td>
                <td>
                  The principal activities of the Company are ship owning and
                  operator of marine vessels for the transportation and offshore
                  storage of oil and gas, provider of port marine services and
                  provision of engineering, procurement, construction,
                  installation and commissioning of floating storage and
                  offloading vessels.
                </td>
              </tr>
              <tr>
                <td>Group Activities</td>
                <td>
                  The E.A. Technique Group is principally an owner and operator
                  of marine vessels where its business is focused on marine
                  transportation and offshore storage of Oil and Gas (O&G), and
                  provision of port marine services. Generally, the Group’s
                  business activities are segmented into the following: (a)
                  Marine Transportation and Offshore Storage of O&G The Group is
                  involved in the charter of various types of tankers for the
                  transportation and offshore storage of O&G, charter of marine
                  support vessels for the provision of port marine services and
                  charter of Offshore Support Vessels (OSV) in the form of fast
                  crew boats to transport personnel/light cargoes between shore
                  and platform, platform and platform and other offshore
                  facilities. As at 31 December 2016, the Group operates a total
                  fleet of 44 marine vessels in their portfolio, which comprises
                  Oil & Gas tankers (inclusive of Floating Storage & Offloading
                  Unit (“FSU/FSO”)), 3 OSVs and 28 marine support vessels. Of
                  the total 44 marine vessels that they operate, the Group owns
                  40 of these marine support vessels. The remaining 4 marine
                  vessels are chartered from external parties. (b) Provision of
                  Port Marine Services The Group is also engaged in the
                  provision of port marine services for petrochemical and bulk
                  and containerised ports in Malaysia. The types of port marine
                  services that the Group provides at the ports include, among
                  others, towage services comprising towing, pushing or
                  manoeuvring vessels; and mooring services involves securing a
                  marine vessel to specially constructed fixtures such as piers,
                  quays, wharfs, jetties, anchor buoys and mooring buoys. The
                  Group also provides dockside mooring services where it has
                  mooring personnel to secure vessels to floating structures and
                  fixtures at the wharf. (c) Marine Engineering Services The
                  Group’s marine engineering services are divided into 2
                  segments which are provision of marine engineering solutions
                  and shipbuilding and ship repair activities. Marine
                  engineering solutions encompass provision of marine
                  engineering solutions and Engineering, Procurement,
                  Construction, Installation & Commissioning (EPCIC) activities.
                  The Group’s shipbuilding and ship repair activities serve as
                  an internal supporting arm to marine vessels. The Group also
                  undertakes minor fabrication of steel structures in its
                  shipyard. The steel structures that it fabricates are mainly
                  for marine vessels, for example helipad, flare stack, skids
                  and piping systems. All these shipbuilding, ship repair and
                  minor fabrications activities are undertaken by its
                  subsidiary, namely Johor Shipyard and Engineering Sdn. Bhd.,
                  which is operating on a 20 acres land in Perak.
                </td>
              </tr>
              <tr>
                <td>Principal Products/Services</td>
                <td>
                  - Marine transportation and offshore storage of O&G -
                  Provision of port marine services - Marine engineering
                  solutions, and shipbuilding and ship repair
                </td>
              </tr>
              <tr>
                <td>Board of Directors</td>
                <td>
                  Ahamad Mohamad Non-Independent Non-Executive Chairman Dato’
                  Ir. Abdul Hak Md Amin Managing Director Datuk Anuar Ahmad
                  Senior Independent Non-Executive Director Datuk Mohd Nasir Ali
                  Independent Non-Executive Director Rozan Mohd Sa’at
                  Non-Independent Non-Executive Director Azli Mohamed
                  Non-Independent Non-Executive Director Abdul Azmin Abdul Halim
                  Independent Non-Executive Director Md Tamyes Hj A. Rahim
                  Independent Non-Executive Director
                </td>
              </tr>
              <tr>
                <td>Company Secretaries</td>
                <td>
                  NURALIZA A. RAHMAN (MAICSA 7067934) SABARUDIN HARUN (MIA
                  30423) Telephone : 607-219 2692 Facsimile : 607-223 3175 Email
                  : nuraliza@jcorp.com.my sabarudin@jcorp.com.my
                </td>
              </tr>
              <tr>
                <td>Registered Office</td>
                <td>
                  {" "}
                  Level 16, Menara KOMTAR Johor Bahru City Centre 80000 Johor
                  Bahru, Johor Telephone : 607-219 5064 Facsimile : 607-223 3175
                </td>
              </tr>
              <tr>
                <td>Business Address</td>
                <td>
                  Setiawangsa Business Suites Unit C-3A-3A No. 2 Jalan
                  Setiawangsa 11 Taman Setiawangsa 54200 Kuala Lumpur, Malaysia
                  Telephone : 603-4252 5422 Facsimile : 603-4252 2163
                </td>
              </tr>
              <tr>
                <td>Share Registrar </td>
                <td>
                  Johor Corporation Level 16, Menara KOMTAR Johor Bahru City
                  Centre 80000 Johor Bahru, Johor Telephone : 607-219 5064
                  Facsimile : 607-223 3175
                </td>
              </tr>
              <tr>
                <td>Auditors</td>
                <td>
                  {" "}
                  Ernst & Young AF: 0039 Chartered Accountants Level 23A, Menara
                  Millennium Jalan Damanlela Pusat Bandar Damansara 50490 Kuala
                  Lumpur, Malaysia Telephone : 603-7495 8000 Facsimile :
                  603-2095 5332 website : www.ey.com
                </td>
              </tr>
              <tr>
                <td>Bankers </td>
                <td>
                  RHB Bank Berhad Malayan Banking Berhad Bank Pembangunan
                  Malaysia Berhad Export-Import Bank of Malaysia Berhad AmBank
                  (M) Berhad Affin Bank Berhad
                </td>
              </tr>
              <tr>
                <td>Website</td>
                <td>www.eatechnique.com.my</td>
              </tr>
            </table>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

General2016Page.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default General2016Page
